<template>
	<div data-route>
		<page-header
			heading="Change your email"
		/>
		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Email"
					type="email"
					autocomplete="username"
					rules="required|email"
					v-model="profile.email"
				/>
				<actions :actions="actions" />
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import PageHeader          from '@/components/ui/PageHeader';
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {
		metaInfo: {
			title: 'Change your email'
		},
		components: {
			PageHeader,
			InputField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			profile: {
				email: ''
			},
			actions: {
				primary: [
					{
						text: 'Change email',
						type: 'submit'
					},
					{
						text: 'Cancel',
						route: '/my-profile'
					}
				]
			}
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/my-profile',
						text: 'My profile'
					},
					{
						path: '/my-profile/change-email',
						text: 'Change your email'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setProfile();
		},
		methods: {
			async setProfile () {
				const profile = await api.auth.getCurrentUserProfile();
				if (!profile) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.profile = {
					...this.profile,
					...profile
				};
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const response = await api.auth.updateCurrentUserEmail({
					email: this.profile.email
				});
				if (!response) {
					return;
				}
				// store the new JWT if it is present
				if (response.token) {
					await this.$store.dispatch('auth/setToken', {
						token: response.token
					});
				}
				await this.$router.push('/my-profile');
				this.$store.commit('ui/showNotification', {
					notification: 'Your email has been updated'
				});
			}
		}
	};

</script>
